const popup = $('.brille');
const close = $('.detail > .close');
const detail = $('.detail');
const showAll = $('.showAll');
let genderLinks = $('a.genderLink');
let typesLinks = $('a.typesLink');
let brandsLinks = $('a.brandsLink');
const glasses = $('.glasses');
const noresults = $('#noresults');

popup.on('click', function () {
  let getPopupId = this.dataset.title;
  let id = getPopupId;
  let element = document.getElementById(id);
  console.log(id)
  console.log(element)
  detail.removeClass('active')
  element.classList.toggle("active")
});

close.on('click', function () {
  detail.removeClass('active')
});


let filterItems = {
  gender: "genderAll",
  type: "typesAll",
  brand: "brandsAll"
};

let queryString = "." + filterItems.gender + "." + filterItems.type + "." + filterItems.brand;


function filterGenders(filterName) {
  filterItems.gender = filterName;
  queryString = "." + filterItems.gender + "." + filterItems.type + "." + filterItems.brand;
  for (var i = 0; i < popup.length; i++) {
    popup[i].classList.remove('active');
  }
  $(queryString).addClass('active');
  if ($('.brille.active').length < 1) {
    noresults.show();
  } else {
    noresults.hide();
  }

}

function filterTypes(filterName) {
  filterItems.type = filterName;
  queryString = "." + filterItems.gender + "." + filterItems.type + "." + filterItems.brand;
  for (var i = 0; i < popup.length; i++) {
    popup[i].classList.remove('active');
  }
  $(queryString).addClass('active');

  if ($('.brille.active').length < 1) {
    noresults.show();
  } else {
    noresults.hide();
  }
}

function filterBrands(filterName) {
  filterItems.brand = filterName;
  queryString = "." + filterItems.gender + "." + filterItems.type + "." + filterItems.brand;
  for (var i = 0; i < popup.length; i++) {
    popup[i].classList.remove('active');
  }
  $(queryString).addClass('active');

  if ($('.brille.active').length < 1) {
    noresults.show();
  } else {
    noresults.hide();
  }

}

genderLinks.on('click', function (e) {
  e.preventDefault();
  genderLinks.removeClass('active');
  $(this).addClass("active");
})

typesLinks.on('click', function (e) {
  e.preventDefault();
  typesLinks.removeClass('active');
  $(this).addClass("active");
})

brandsLinks.on('click', function (e) {
  e.preventDefault();
  brandsLinks.removeClass('active');
  $(this).addClass("active");
})